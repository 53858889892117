import { fetchAccessToken } from "@origin-digital/event-dispatcher";
import { DecodedJwt, decodeJwt } from "@origin-digital/origin-auth";

import { config } from "../config";

type DecodedJwtWithBankend = DecodedJwt & {
  payload: {
    "https://originenergy.com.au/backends"?: string[];
    "https://originenergy.com.au/masqueradeId"?: string;
    "https://originenergy.com.au/userType"?: string;
  };
};

export type JWTClaims = {
  customerType: string | undefined;
  backends: string[] | undefined;
  masqueradeId: string | undefined;
  userType: string | undefined;
};

export const getJwtToken = async () => {
  const jwt = await fetchAccessToken({
    environment: config.env
  });
  return jwt;
};

export const getUserUid = (jwt: DecodedJwt): string | undefined => {
  const {
    payload: { sub = "" }
  } = jwt;
  return sub ? sub.split("|").pop() : undefined;
};

export const isSameUser = (jwtA?: DecodedJwt, jwtB?: DecodedJwt): boolean => {
  if (!jwtA || !jwtB) return false;
  return getUserUid(jwtA) === getUserUid(jwtB);
};

export const decodeJwtWithBackend = (
  accessToken: string
): DecodedJwtWithBankend => {
  return decodeJwt(accessToken) as DecodedJwtWithBankend;
};

export const getJwtClaims = (accessToken: string | undefined): JWTClaims => {
  if (!accessToken) {
    return {
      backends: undefined,
      customerType: undefined,
      masqueradeId: undefined,
      userType: undefined
    };
  }

  const jwt = decodeJwtWithBackend(accessToken);
  const backends = jwt.payload["https://originenergy.com.au/backends"];
  const customerType = jwt.payload["https://originenergy.com.au/customerType"];
  const masqueradeId = jwt.payload["https://originenergy.com.au/masqueradeId"];
  const userType = jwt.payload["https://originenergy.com.au/userType"];

  return {
    backends,
    customerType,
    masqueradeId,
    userType
  };
};

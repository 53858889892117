import { OriginWebAuthClient, storeJwt } from "@origin-digital/origin-auth";
import { ADOBE_LOGIN_EVENT_TYPE_MAGIC_FULL_SCOPE } from "../const";
import { saveAnalyticsLoginType } from "../utils/analytics";
import {
  fireAdobeLoginSuccessEvent,
  getAuth0Config,
  getLoggerConfig
} from "../utils/utils";
import { redirectTo } from "./auth";

export async function tokenExchange(
  accessToken: string,
  returnToPath?: string
) {
  const auth0Config = getAuth0Config();
  const webAuthClient = new OriginWebAuthClient(auth0Config, getLoggerConfig());

  try {
    storeJwt(accessToken, auth0Config);
    saveAnalyticsLoginType(ADOBE_LOGIN_EVENT_TYPE_MAGIC_FULL_SCOPE);
    await fireAdobeLoginSuccessEvent();

    return redirectTo(webAuthClient, returnToPath);
  } catch (error) {
    webAuthClient.handleError(error.AUTH_CALLBACK_AUTH0_CALL, error);
  }
}

import {
  decodeJwt,
  IReportingClientConfig,
  REMEMBER_ME
} from "@origin-digital/origin-auth";
import { logger, LogLevel } from "@origin-digital/reporting-client";
import queryString from "query-string";
import {
  ADOBE_LOGIN_EVENT_STATUS_SUCCESS,
  APP_ID,
  APP_NAME_AUTH_CALLBACK,
  AUTH0_CONFIG
} from "../const";
import { fireAnalytics, getLoginMethod } from "./analytics";
import { getJwtClaims, getJwtToken, getUserUid, JWTClaims } from "./jwt";
import { LoginFlowType } from "./LoginFlowType";

export const isRememberMe = (): boolean => {
  // 'remember_me' will always be set the in local storage. If the user ticked 'keep me logged in' on auth0 hosted-login page
  // 'remember_me' will be set true otherwise it will be set to false.
  return localStorage.getItem(REMEMBER_ME) === "true";
};

export const fireAdobeLoginSuccessEvent = async (): Promise<
  Response | undefined
> => {
  try {
    const loginMethod = getLoginMethod();
    const customerData = await getTrackingUserData();
    return await fireAnalytics(window.location.host, {
      action: "login",
      status: ADOBE_LOGIN_EVENT_STATUS_SUCCESS,
      loginMethod,
      ...customerData
    });
  } catch (e) {
    // ignore errors
  }
};

/**
 *  Returns auth0 config.
 *  If app-specific auth0 config is not provided then
 *  returns the env specific auth0 config
 *
 *  overwrites clientId if provided in url, this is to support new clientId from
 *  native app. JIRA: https://origindd.atlassian.net/browse/APP-993
 *  e.g. https://localhost/?clientId=[newClientId]
 */
export function getAuth0Config(): IAuth0Config {
  const appAuth0Config = sessionStorage.getItem(AUTH0_CONFIG);
  const envAuth0Config = window.oetal.config[APP_ID]
    .auth0Config as IAuth0Config;
  const auth0Config = appAuth0Config
    ? (JSON.parse(appAuth0Config) as IAuth0Config)
    : envAuth0Config;
  const queryParams = new URLSearchParams(window.location.search);
  const clientIdFromUrl = queryParams.get("clientId");
  if (clientIdFromUrl) {
    auth0Config.clientId = clientIdFromUrl;
  }
  return auth0Config;
}

export function getLoggerConfig() {
  const talLoggingConfig = window.oetal.config[APP_ID].logging;
  const loggerConfig: IReportingClientConfig = talLoggingConfig
    ? {
        ...talLoggingConfig,
        appName: APP_NAME_AUTH_CALLBACK
      }
    : talLoggingConfig;
  return loggerConfig;
}

export async function log(logLevel: LogLevel, message: string, data?: any) {
  try {
    await logger.log(logLevel, message, data);
  } catch (e) {
    console.log("Error with logger " + e.message);
  }
}

export function parseReturnToQueryParam(searchStr: string): string | undefined {
  const queryParams = queryString.parse(searchStr) as {
    [key: string]: string;
  };

  return !!queryParams.returnTo
    ? decodeURIComponent(queryParams.returnTo)
    : undefined;
}

export function parseToggleFromQueryParam(
  searchStr: string,
  toggleName: string
): boolean {
  const queryParams = queryString.parse(searchStr) as {
    [key: string]: string;
  };

  return !!queryParams[toggleName]
    ? queryParams[toggleName].toLowerCase() === "true"
    : false;
}

export function appendQueryParams(url: string) {
  const parsedSearch = queryString.parse(window.location.search);
  return Object.keys(parsedSearch).length === 0
    ? url
    : url + "?" + queryString.stringify(parsedSearch);
}

export function getCustomerIdFromReturnToUrl(): string {
  // the bpid should be avaialable if the
  // magic link client passess it during
  // magic link creation
  // the param would be made avaialble as a url param in the returnTo url param
  const returnToUrl = parseReturnToQueryParam(window.location.search);
  const returnToQueryParams = new URLSearchParams(returnToUrl);
  return returnToQueryParams.get("bp") || "";
}

export const getTrackingUserData = async (): Promise<{
  [name: string]: string;
}> => {
  const accessToken = await getJwtToken();
  const claims = getJwtClaims(accessToken && accessToken.accessToken);
  const loginFlowType = getTargetLoginFlow(
    claims,
    "getTrackingDataFn",
    accessToken && accessToken.accessToken
  );
  if (accessToken && loginFlowType === LoginFlowType.RX) {
    return {
      customerId:
        getUserUid(decodeJwt(accessToken.accessToken)) ||
        getCustomerIdFromReturnToUrl()
    };
  }
  return {
    bpid: getCustomerIdFromReturnToUrl()
  };
};

/**
 * Determine the best login flow required for a customer based on the jwt claims of that customer
 */
export const getTargetLoginFlow = (
  claims: JWTClaims,
  context: string = "",
  accessToken: string = ""
): LoginFlowType => {
  const { backends, customerType } = claims;
  if (backends || customerType) {
    return LoginFlowType.RX;
  } else {
    logger.error(
      `[login-flow-type] Context: ${context} Unknown login flow type. Unable to login. Customer type: ${claims &&
        claims.customerType}, Backends: ${claims &&
        claims.backends}, JWT: ${accessToken}`
    );
    return LoginFlowType.UNKNOWN;
  }
};
